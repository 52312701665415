import React, { useState, useEffect, useRef } from "react";
import DrEvent from "../../display/DrEvent";

function CoinChallenge(props) {
  const [coinFlipping, setCoinFlipping] = useState(false);
  const [coinFlipped, setCoinFlipped] = useState(false);

  useEffect(() => {
    setCoinFlipping(true);
    const timeout = setTimeout(() => {
      setCoinFlipped(true);
    }, 4000);
    return () => clearTimeout(timeout); // Cleanup
  }, []);

  const iconPaths = [
    "game piece-0.png",
    "game piece-01.png",
    "game piece-02.png",
    "game piece-03.png",
    "game piece-04.png",
    "game piece-05.png",
    "game piece-06.png",
    "game piece-07.png",
    "game piece-08.png",
    "game piece-09.png",
    "game piece-10.png",
    "game piece-11.png",
    "portrait4x.png",
  ];

  useEffect(() => {
    if (coinFlipping) {
      const coin = document.getElementById("coin");
      setInterval(() => {
        if (props.coinFlip) {
          coin.classList.add("heads");
        } else {
          coin.classList.add("tails");
        }
      }, 100);
    }
  }, [coinFlipping]);

  function highlight(text) {
    const groupNameStyle = { color: "var(--baseColor)" };
    const playerNameStyle = { color: props.playerColor };
    text = text.replace(
      /<any>/g,
      `<span style="${Object.entries(groupNameStyle)
        .map(([key, value]) => `${key}:${value}`)
        .join(";")}" class="playerName">Anyone</span>`
    );
    text = text.replace(
      /<all>/g,
      `<span style="${Object.entries(groupNameStyle)
        .map(([key, value]) => `${key}:${value}`)
        .join(";")}" class="playerName">Everyone</span>`
    );
    return text.replace(
      /<name>/g,
      `<span style="${Object.entries(playerNameStyle)
        .map(([key, value]) => `${key}:${value}`)
        .join(";")}" class="playerName">${props.turnPlayerName}</span>`
    );
  }

  function getPromptColor() {
    if (!coinFlipped) {
      return "black";
    }
    if (props.coinFlip) {
      return "black";
    }
    return "#FF3333";
  }

  function renderNav() {
    if (props.turnPlayer === props.yourId) {
      return (
        <button
          className="splashNavBtn"
          style={{ opacity: coinFlipped ? 1 : 0 }}
          onClick={coinFlipped ? props.handleClose : null}
        >
          END TURN
        </button>
      );
    }
  }

  function renderPrompt() {
    if (!coinFlipped) {
      return `${props.turnPlayerName} chose to flip!`;
    } else {
      if (props.coinFlip) {
        return `${props.turnPlayerName}, pick 2 players to take a shot!`;
      } else {
        return `${props.turnPlayerName} take a shot!`;
      }
    }
  }

  function renderBody() {
    return (
      <div className="coinWrapper">
        <div className="coinContainer" id="coin">
          <div className="coinContainer side-a">
            <div className="coinTextTop">
              {coinFlipping || coinFlipped ? "Give" : null}
            </div>
            <img className="coinIcon" src={`/icons/${iconPaths[12]}`} />
            <div className="coinTextBot">
              {coinFlipping || coinFlipped ? "2 Shots" : null}
            </div>
          </div>
          <div className="coinContainer side-b">
            <div className="coinTextTop">
              {coinFlipping || coinFlipped ? "Take" : null}
            </div>
            <img
              className="coinIcon"
              src={`/icons/${iconPaths[props.iconId]}`}
            />
            <div className="coinTextBot">
              {coinFlipping || coinFlipped ? "1 Shot" : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <DrEvent className="challengeContainer">
      <div
        className="pokerPrompt"
        style={{ color: getPromptColor() ? getPromptColor() : "" }}
      >
        <p dangerouslySetInnerHTML={{ __html: highlight(renderPrompt()) }}></p>
      </div>
      {renderBody()}
      {renderNav()}
    </DrEvent>
  );
}
export default CoinChallenge;
